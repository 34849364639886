/* eslint-disable */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { bodyClass, bodyClassPrefix } from './Constants';
import Layout from '../../components/composite/Layout';
import Seo from '../../components/simple/seo';
import PostPage from '../../components/pages/PostPage';
import processQueried from './helpers/processQueried';
import parseLayoutProps from '../common/parse/parseLayoutProps';
import wpInlineImageWorkaround from '../../utilities/wpInlineImageWorkaround';
import { cdmblogPostSingle } from './PostContainer.module.scss';
import useLocation from '../../utilities/useLocation'

/* following imports are GraphQL fragments, they are used within the page query */
import {
    menuNodes,
    menuItemNodes,
    postData,
    postCardData,
    siteMetadata,
} from '../../utilities/fragments/';

const PostContainer = ({ data, location, isPreview }) => {
    useLocation(location);
    const { footerProps, headerProps } = parseLayoutProps(data, location);
    const { postArgs, headArgs } = processQueried(data, location);

    useEffect( wpInlineImageWorkaround, []);

    return (
        <Layout headerProps={headerProps} footerProps={footerProps} isPreview={isPreview}>
            <Seo
                helmetProps={headArgs}
                bodyClass={`${cdmblogPostSingle} ${bodyClass} ${bodyClassPrefix}${data.post?.databaseId}`}
            />
            <PostPage postInfo={postArgs} />
        </Layout>
    );
};
export default PostContainer;
PostContainer.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    isPreview: PropTypes.bool,
};

PostContainer.defaultProps = {
    isPreview: false,
}

export const pageQuery = graphql`
    query BlogPostById(
        $id: String!
        $pinnedPostIds: [Int]
        $categoryIds: [Int]
    ) {
        site {
            ...siteMetadata
        }
        post: wpPost(id: { eq: $id }) {
            ...postData
        }
        FactCheckedDisclosure: wpDisclosure(
            cdmfields: { uislot: { slotname: { eq: "fact_checked" } } }
        ) {
            content
        }
        EdDisclosure: wpDisclosure(
            cdmfields: { uislot: { slotname: { eq: "editorial" } } }
        ) {
            content
        }
        headerMenuNav: allWpMenuItem(
            filter: { locations: { eq: MENU_1 }, parentDatabaseId: { eq: 0 } }
        ) {
            ...menuItemNodes
        }
        footerMenuPolicy: wpMenu(locations: { eq: MENU_2 }) {
            menuItems {
                ...menuNodes
            }
        }
        footerMenuCol1: wpMenu(locations: { eq: FOOTER_COL_1 }) {
            menuItems {
                ...menuNodes
            }
        }
        footerMenuCol2: wpMenu(locations: { eq: FOOTER_COL_2 }) {
            menuItems {
                ...menuNodes
            }
        }
        pinnedPosts: allWpPost(filter: { databaseId: { in: $pinnedPostIds } }) {
            edges {
                node {
                    ...postCardFields
                }
            }
        }
        relatedPosts: allWpPost(
            filter: {
                categories: {
                    nodes: { elemMatch: { databaseId: { in: $categoryIds } } }
                }
            }
        ) {
            edges {
                node {
                    ...postCardFields
                }
            }
        }

        relatedGuides: allWpGuide(
            filter: {
                categories: {
                    nodes: { elemMatch: { databaseId: { in: $categoryIds } } }
                }
            }
        ) {
            edges {
                node {
                    ...guideCardFields
                }
            }
        }
    }
`;
